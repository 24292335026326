import React from 'react'
import { Link } from 'gatsby'
import Button from '../components/Button/Button'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import { useStaticQuery, graphql } from 'gatsby'

import '../components/ContactFormFooter/ContactFormFooter.css'

const ModalProjekteInfos = ({ data }) => {
  const { titelModal, shortIntroText, legalText} = data.contentfulProjectInfoForm 
  
  return (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <div class="modal-info max-w-lg">
        {modal ? (
          <div className="flex close-modal-icon-wrapper w-full items-end"><Link to={closeTo}>
            <div className="close-modal-icon">+</div>
          </Link></div>
        ) : (
          <Link to="/">Zurück zur Website</Link>
        )}
        <div className="">
        <h2 className="mt-8 text-center leading-snug">{titelModal}</h2>
        <p className="mt-8 md:mt-16 mb-8">{shortIntroText}</p>
        </div>
        <form className='flex flex-wrap justify-center items-start w-full' name='project-info' method='POST' action='/thank-you' data-netlify='true' data-netlify-honeypot='bot-field'>
        <div className='flex w-full justify-center'>
            <div className='flex w-full max-w-6xl flex-wrap justify-center'>
                <div className='flex w-full flex-col w-full max-w-4xl'>
                    <input type='hidden' name='bot-field' />
                    <input type='hidden' name='form-name' value='project-info' />
                    <input className='bg-gray-200 w-full' type='email' name='email' placeholder='Ihre E-Mail Adresse' required />
                </div>
                <div className='flex flex-col w-full max-w-4xl'>
                <div className='job-form-dsgvo relative w-full h-40 sm:h-24'>
                    <input className='absolute w-full cursor-pointer z-10 opacity-0 h-32 sm:h-20 bg-gray-200' type='checkbox' name='dsgvo' required />
                    <span className='checkbox bg-gray-200'></span>
        <span className='text-button block ml-12'>{legalText}</span>
                </div>
                <Button className='text-button mb-6' href='/datenschutzerklaerung' target='_blank' withArrow ><span className='border-b-2 border-transparent hover:border-black'>Unsere Datenschutzerklärung</span></Button>
                <Button className='self-center btn-filled btn-black hidden lg:block mt-8' withArrow type='submit'>Abschicken</Button>
                </div>
                <Button className='self-center btn-filled btn-black block lg:hidden' withArrow type='submit'>Abschicken</Button>
            </div>
        </div>
        </form>
      </div>
    )}
  </ModalRoutingContext.Consumer>
)
        }
export default ModalProjekteInfos

export const ModalProjekteInfosQuery = graphql`
query {
  contentfulProjectInfoForm(slug: {eq: "projekt-infos-anfordern"}) {
    titelModal
    shortIntroText
    legalText
  }
}

`