import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import './Button.css'
import ArrowRight from '../../images/svgs/cta-arrow-right.svg'

const Button = ({ type, to, href, target, className, arrowStyle, withArrow, children }) => {
  return (
    <>
      {to &&
        <Link to={to} className={`btn transition ${className}`}>
          {withArrow &&
            <ArrowRight className={`stroke-current hover:stroke-current w-8 inline mr-2 ${arrowStyle}`} />
          }
          {children}
        </Link>
      }
      {href &&
        <a href={href} target={target} className={`btn transition ${className}`}>
          {withArrow &&
            <ArrowRight className='stroke-current hover:stroke-current w-8 inline mr-2' />
          }
          {children}
        </a>
      }
      {!to && !href &&
        <button type={type} className={`btn transition ${className}`}>
          {withArrow &&
            <ArrowRight className='stroke-current hover:stroke-current w-8 inline mr-2' />
          }
          {children}
        </button>
      }
    </>
  )
}

Button.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  withArrow: PropTypes.bool,
  children: PropTypes.node,
  type: PropTypes.string,
}

Button.defaultProps = {
  type: 'button',
}

export default Button
